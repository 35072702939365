import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { login } from "../api/Api";
import { ACCESS_TOKEN, ADMIN_TOKEN } from "../config/Config";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../contexts/StateContext";

const SignIn = () => {
  const navigate = useNavigate();
  const isLoggedIn = useContextSelector(StateContext, (state) => state.isLoggedIn);
  const setIsLoggedIn = useContextSelector(StateContext, (state) => state.setIsLoggedIn);
  const setRole = useContextSelector(StateContext, (state) => state.setRole);
  const setUserName = useContextSelector(StateContext, (state) => state.setUserName);
  const [formData, setFormData] = useState({ email: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.email) {
      return toast.error("Enter your email");
    }

    const loginRequest = { usernameOrEmail: formData.email };
    login(loginRequest)
      .then((res) => {
        toast.success("Email verified.");
        localStorage.setItem(ACCESS_TOKEN, res.accessToken || null);
        setIsLoggedIn(true);

        const base64Url = res.accessToken.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        const token = JSON.parse(window.atob(base64));

        if (token.exp <= Math.floor(Date.now() / 1000)) {
          localStorage.clear();
          setIsLoggedIn(false);
        } else {
          setUserName(token.email);
          setRole(token.rol[0]);
        }

        navigate("/home");
      })
      .catch((error) => {
        toast.error(error.status === 401 ? "Invalid credentials." : error.message);
      });
  };

  return (
    <div className="bg-gray-100 py-12 mt-28">
      <h2 className="text-3xl text-blue-900 uppercase font-bold text-center mb-4">Sign In</h2>
      <form className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-md" onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter your email"
            value={formData.email}
            onChange={handleChange}
            className="border border-gray-300 rounded-md w-full p-2 mt-1"
          />
        </div>
        <button type="submit" className="bg-blue-900 hover:bg-blue-700 text-white py-2 px-4 rounded w-full mt-4">
          Sign In
        </button>
      </form>
      <Toaster
        position="bottom-right"
        toastOptions={{
          error: { duration: 6000, style: { background: "#FF4136", color: "#fff" } },
          success: { duration: 6000, style: { background: "#008000", color: "#fff" } },
        }}
      />
    </div>
  );
};

export default SignIn;
